@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
[data-rsbs-overlay] {
  border-top-left-radius: 16px;
  border-top-left-radius: var(--rsbs-overlay-rounded,16px);
  border-top-right-radius: 16px;
  border-top-right-radius: var(--rsbs-overlay-rounded,16px);
  display: flex;
  background: #fff;
  background: var(--rsbs-bg,#fff);
  flex-direction: column;
  height: 0px;
  height: var(--rsbs-overlay-h,0px);
  transform: translate3d(0, 0px, 0);
  transform: translate3d(0, var(--rsbs-overlay-translate-y,0px), 0);
  will-change: height;
}

[data-rsbs-overlay]:focus {
  outline: none;
}

[data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
  box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),
    0 -1px 0 rgba(38, 89, 115, 0.05);
}

[data-rsbs-overlay],
[data-rsbs-root]:after {
  max-width: auto;
  max-width: var(--rsbs-max-w,auto);
  margin-left: env(safe-area-inset-left);
  margin-left: var(--rsbs-ml,env(safe-area-inset-left));
  margin-right: env(safe-area-inset-right);
  margin-right: var(--rsbs-mr,env(safe-area-inset-right));
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 3;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

[data-rsbs-backdrop] {
  top: -60px;
  bottom: -60px;
  background-color: rgba(0, 0, 0, 0.6);
  background-color: var(--rsbs-backdrop-bg,rgba(0, 0, 0, 0.6));
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
}

[data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
  cursor: ns-resize;
}

[data-rsbs-root]:after {
  content: '';
  pointer-events: none;
  background: #fff;
  background: var(--rsbs-bg,#fff);
  height: 1px;
  transform-origin: bottom;
  transform: scale3d(1, 0, 1);
  transform: scale3d(1, var(--rsbs-antigap-scale-y,0), 1);
  will-change: transform;
}

[data-rsbs-footer],
[data-rsbs-header] {
  flex-shrink: 0;
  cursor: ns-resize;
  padding: 16px;
}

[data-rsbs-header] {
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(1 * 0.125));
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(var(--rsbs-content-opacity,1) * 0.125));
  z-index: 1;
  padding-top: calc(20px + env(safe-area-inset-top));
  padding-bottom: 8px;
}

[data-rsbs-header]:before {
  position: absolute;
  content: '';
  display: block;
  width: 36px;
  height: 4px;
  top: calc(8px + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: hsla(0, 0%, 0%, 0.14);
  background-color: var(--rsbs-handle-bg,hsla(0, 0%, 0%, 0.14));
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  [data-rsbs-header]:before {
    transform: translateX(-50%) scaleY(0.75);
  }
}

[data-rsbs-has-header='false'] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(12px + env(safe-area-inset-top));
}

[data-rsbs-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  -moz-user-select: auto;
       user-select: auto;
  overflow: auto;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

[data-rsbs-scroll]:focus {
  outline: none;
}

[data-rsbs-has-footer='false'] [data-rsbs-content] {
  padding-bottom: env(safe-area-inset-bottom);
}

[data-rsbs-content] {
  /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
  overflow: hidden;
}

[data-rsbs-footer] {
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(1 * 0.125)),
    0 2px 0 #fff;
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity,1) * 0.125)),
    0 2px 0 var(--rsbs-bg,#fff);
  overflow: hidden;
  z-index: 1;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

[data-rsbs-is-dismissable='true'] [data-rsbs-header] > *, [data-rsbs-is-dismissable='true'] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable='true'] [data-rsbs-footer] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-header] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-header] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-footer] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-footer] > * {
    opacity: 1;
    opacity: var(--rsbs-content-opacity,1);
  }

[data-rsbs-is-dismissable='true'] [data-rsbs-backdrop], [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-backdrop], [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-backdrop] {
    opacity: 1;
    opacity: var(--rsbs-backdrop-opacity,1);
  }

[data-rsbs-state='closed'],
[data-rsbs-state='closing'] {
  /* Allows interactions on the rest of the page before the close transition is finished */
  pointer-events: none;
}

._3pYe- {
  margin: 0;
  display: flex;
  outline: none;
  cursor: pointer;
  padding: 0 12px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  --colorPrimary: var(--primaryDefault);
}

._dVbRj {
  --colorPrimary: var(--neutralPrimaryBlack);
}

._1veU8 {
  height: 34px;
}

._PuJ1c {
  height: 40px;
}

._1OMLH {
  height: 48px;
}

._2bLJ6 {
  height: 56px;
}

._XJaq- {
  border: none;
  background-color: var(--colorPrimary);
}

._32Ryc {
  border: none;
  background-color: var(--neutralPrimary);
}

._6Lsbn {
  border: none;
  background-color: var(--textDisabled);
}

._fhlqk {
  border: 1px solid var(--colorPrimary);
  background-color: transparent;
}

._1qWoc {
  border: 1px solid var(--neutralPrimary);
  background-color: transparent;
}

._3SvTq {
  border: 1px solid var(--neutralBorder);
  background-color: transparent;
}

._1wHfV {
  border: 1px solid var(--textDisabled);
  background-color: transparent;
}

._AEnYI {
  border: none;
  background-color: transparent;
}

._3hF-X {
  padding: 0;
  margin: 0;
}

._2vMYe {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

._1imQH {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

._38TCU {
  color: var(--colorPrimary);
}

._3JCPO {
  color: var(--neutralPrimary);
}

._1_OV_ {
  color: var(--textDisabled);
}

._GYI0z {
  color: var(--textPrimary);
}

._3ITUm {
  display: flex;
  align-items: center;
  justify-content: center;
}

._2JDoG {
  margin-right: 4px;
}

._2hfPT {
  margin-left: 4px;
}

._3puNp {
  width: 24px;
  height: 24px;
  font-size: 16px;
}

._2GI5t {
  width: 18px;
  height: 18px;
  font-size: 14px;
}

._3Pz8M {
  padding: 0 min(12 / var(--ui-kit-design-width) * 100vw, 
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  border-radius: min(8 / var(--ui-kit-design-width) * 100vw, 
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3Pz8M._1veU8 {
  height: min(34 / var(--ui-kit-design-width) * 100vw, 
    34px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3Pz8M._PuJ1c {
  height: min(40 / var(--ui-kit-design-width) * 100vw, 
    40px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3Pz8M._1OMLH {
  height: min(48 / var(--ui-kit-design-width) * 100vw, 
    48px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3Pz8M._2bLJ6 {
  height: min(56 / var(--ui-kit-design-width) * 100vw, 
    56px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3Pz8M._fhlqk {
  border: min(1 / var(--ui-kit-design-width) * 100vw, 
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) solid var(--colorPrimary);
}
._3Pz8M._1qWoc {
  border: min(1 / var(--ui-kit-design-width) * 100vw, 
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) solid var(--neutralPrimary);
}
._3Pz8M._1wHfV {
  border: min(1 / var(--ui-kit-design-width) * 100vw, 
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) solid var(--textDisabled);
}
._3Pz8M ._2vMYe {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: min(16 / var(--ui-kit-design-width) * 100vw, 
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  line-height: min(24 / var(--ui-kit-design-width) * 100vw, 
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3Pz8M ._1imQH {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: min(12 / var(--ui-kit-design-width) * 100vw, 
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  line-height: min(18 / var(--ui-kit-design-width) * 100vw, 
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3Pz8M ._2JDoG {
  margin-right: min(4 / var(--ui-kit-design-width) * 100vw, 
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3Pz8M ._2hfPT {
  margin-left: min(4 / var(--ui-kit-design-width) * 100vw, 
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3Pz8M ._3puNp {
  width: min(24 / var(--ui-kit-design-width) * 100vw, 
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  height: min(24 / var(--ui-kit-design-width) * 100vw, 
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  font-size: min(16 / var(--ui-kit-design-width) * 100vw, 
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3Pz8M ._2GI5t {
  width: min(18 / var(--ui-kit-design-width) * 100vw, 
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  height: min(18 / var(--ui-kit-design-width) * 100vw, 
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  font-size: min(14 / var(--ui-kit-design-width) * 100vw, 
    14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
/* NEUTRAL COLORS */
/* TEXT / STATES */
/* PRIMARY COLORS */
/* ERROR COLORS */
/* WARNING COLORS */
/* SUCCESS COLORS */
/* INFO COLORS */
/* CAUTION COLORS */
/* Modal */
/* OTHERS COLORS */
._cDuLU {
  display: flex;
  align-items: center;
}
._cDuLU ._2pq2t {
  border-radius: 100%;
  display: inline-block;
  animation-fill-mode: both;
}
._cDuLU ._1V5ud {
  animation-duration: 0.75s;
  animation-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  animation-delay: 0.12s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-name: _1xoZ9;
}
._cDuLU ._3UDgp {
  margin: 0 min(8 / var(--ui-kit-design-width) * 100vw, 
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  animation-duration: 0.75s;
  animation-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  animation-delay: 0.24s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-name: _1xoZ9;
}
._cDuLU ._3IkD- {
  animation-duration: 0.75s;
  animation-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  animation-delay: 0.36s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-name: _1xoZ9;
}

@keyframes _1xoZ9 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  45% {
    transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
}

._24KnW {
  border: none;
  display: flex;
  padding: 0 2px;
  cursor: pointer;
  min-height: 42px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

._3DMz3 {
  display: flex;
  align-items: center;
  flex-direction: row;
}

._cGcZj {
  border-radius: 8px;
}

._x_zl9 {
  background-color: var(--neutralBase);
}

._3wadd {
  background-color: var(--successBackground);
}

._1VPpw {
  background-color: var(--warningBackground);
}

._1ze3z {
  background-color: var(--errorBackground);
}

._oK6s8 {
  padding: 0;
  margin: 0 2px 0 10px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

._oGzs5 {
  color: var(--warningHover);
}

._3tmg_ {
  color: var(--errorDefault);
}

._3QkXi {
  color: var(--textPrimary);
}

._2FQk4 {
  color: var(--successDefault);
}

._2mZrr {
  font-size: 14px;
  margin-left: 12px;
}

._2ZLVN {
  margin: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

._2tD79 {
  font-size: 14px;
}

._1X-ji {
  padding: 0 min(2 / var(--ui-kit-design-width) * 100vw, 
    2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  min-height: min(42 / var(--ui-kit-design-width) * 100vw, 
    42px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._1X-ji ._cGcZj {
  border-radius: min(8 / var(--ui-kit-design-width) * 100vw, 
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._1X-ji ._oK6s8 {
  margin: 0 min(2 / var(--ui-kit-design-width) * 100vw, 
    2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) 0 min(10 / var(--ui-kit-design-width) * 100vw, 
    10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: min(12 / var(--ui-kit-design-width) * 100vw, 
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  line-height: min(18 / var(--ui-kit-design-width) * 100vw, 
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._1X-ji ._2mZrr {
  font-size: min(14 / var(--ui-kit-design-width) * 100vw, 
    14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  margin-left: min(12 / var(--ui-kit-design-width) * 100vw, 
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._1X-ji ._2ZLVN {
  width: min(32 / var(--ui-kit-design-width) * 100vw, 
    32px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  height: min(32 / var(--ui-kit-design-width) * 100vw, 
    32px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._1X-ji ._2tD79 {
  font-size: min(14 / var(--ui-kit-design-width) * 100vw, 
    14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}

._2WOf_ {
  border: none;
  display: flex;
  cursor: pointer;
  min-height: 42px;
  overflow: hidden;
  padding: 0 0 0 2px;
  align-items: center;
  flex-direction: row;
  background-color: var(--successDefault);
  justify-content: space-between;
}

._Th0LL {
  border-radius: 8px;
}

._3jOaV {
  padding: 0;
  color: var(--neutralPrimary);
  margin: 0 10px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

._2g_Fa {
  color: var(--neutralPrimary);
  font-size: 14px;
  margin-left: 12px;
}

._3G2m5 {
  margin: 0;
  height: 42px;
  border: none;
  display: flex;
  padding: 0 12px;
  cursor: pointer;
  align-items: center;
  background-color: var(--successHover);
}

._y9_Sb {
  margin: 0;
  padding: 0;
  color: var(--neutralPrimary);
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

._3MVMK {
  min-height: min(42 / var(--ui-kit-design-width) * 100vw, 
    42px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  padding: 0 0 0 min(2 / var(--ui-kit-design-width) * 100vw, 
    2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3MVMK._Th0LL {
  border-radius: min(8 / var(--ui-kit-design-width) * 100vw, 
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3MVMK ._3jOaV {
  margin: 0 min(10 / var(--ui-kit-design-width) * 100vw, 
    10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: min(12 / var(--ui-kit-design-width) * 100vw, 
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  line-height: min(18 / var(--ui-kit-design-width) * 100vw, 
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3MVMK ._2g_Fa {
  font-size: min(14 / var(--ui-kit-design-width) * 100vw, 
    14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  margin-left: min(12 / var(--ui-kit-design-width) * 100vw, 
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3MVMK ._3G2m5 {
  height: min(42 / var(--ui-kit-design-width) * 100vw, 
    42px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  padding: 0 min(12 / var(--ui-kit-design-width) * 100vw, 
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._3MVMK ._y9_Sb {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: min(14 / var(--ui-kit-design-width) * 100vw, 
    14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  line-height: min(20 / var(--ui-kit-design-width) * 100vw, 
    20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._9B1IW {
  --rsbs-backdrop-bg: var(--modalOverlayBlack) !important;
  --rsbs-bg: var(--neutralPrimary);
  --rsbs-max-w: var(--hui-bottomsheet-max-width, auto);
  --rsbs-ml: auto;
  --rsbs-mr: auto;
  --rsbs-overlay-rounded: 20px !important;
}
._9B1IW [data-rsbs-header]:before {
  background-color: var(--textTertiary);
  opacity: 0.3;
}
._9B1IW [data-rsbs-backdrop] {
  pointer-events: auto;
}
._9B1IW._1LQJ6 [data-rsbs-header] {
  display: none;
}

._28pKe {
  --rsbs-overlay-rounded: min(20 / var(--ui-kit-design-width) * 100vw, 
    20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) !important;
}
._3TBrM {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  overflow: hidden;
}
._3TBrM._2UvtF {
  position: absolute;
}
._3TBrM._36pTF {
  pointer-events: auto;
}
._3TBrM._36pTF._1D3qi {
  background-color: var(--modalOverlayBlack);
}
._3TBrM ._1ewLV {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
._3TBrM ._1ewLV ._3u99Z {
  pointer-events: auto;
  width: 100%;
  min-height: 50px;
  background-color: var(--neutralPrimary);
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  overflow: auto;
}
._3TBrM ._1ewLV ._2VodM {
  pointer-events: auto;
  margin: 24px auto 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--neutralPrimary);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--textPrimary);
  font-size: 16px;
}
._3TBrM ._1ewLV._1QGm1 {
  opacity: 0;
}
._3TBrM ._1ewLV._1QGm1._oD3eX {
  opacity: 1;
}
._3TBrM ._1ewLV._2HW8m {
  transform: translateY(100%);
}
._3TBrM ._1ewLV._2HW8m._oD3eX {
  transform: translateY(0);
}
._3TBrM ._1ewLV._1wB-X {
  transform: translateY(-100%);
}
._3TBrM ._1ewLV._1wB-X._oD3eX {
  transform: translateY(0);
}
._3TBrM ._1ewLV._TFN7_ {
  transform: translateX(100%);
}
._3TBrM ._1ewLV._TFN7_._oD3eX {
  transform: translateX(0);
}
._3TBrM ._1ewLV._1Pk7T {
  transform: translateX(-100%);
}
._3TBrM ._1ewLV._1Pk7T._oD3eX {
  transform: translateX(0);
}
._3TBrM ._1ewLV._3aD9L {
  height: 100%;
  padding: 0;
}
._3TBrM ._1ewLV._3aD9L._2HW8m {
  flex-direction: column-reverse;
  justify-content: flex-start;
}
._3TBrM ._1ewLV._3aD9L._2HW8m ._2VodM {
  margin: 0 auto 24px;
}
._3TBrM ._1ewLV._3aD9L._1wB-X {
  justify-content: flex-start;
}
._3TBrM ._1ewLV._3aD9L._TFN7_ {
  flex-direction: row-reverse;
  justify-content: flex-start;
}
._3TBrM ._1ewLV._3aD9L._TFN7_ ._2VodM {
  margin: auto 24px auto 0;
}
._3TBrM ._1ewLV._3aD9L._1Pk7T {
  flex-direction: row;
  justify-content: flex-start;
}
._3TBrM ._1ewLV._3aD9L._1Pk7T ._2VodM {
  margin: auto 0 auto 24px;
}
._3TBrM ._1ewLV._3aD9L ._3u99Z {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

._2ZelF ._1ewLV {
  padding: 0 min(16 / var(--ui-kit-design-width) * 100vw, 
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._2ZelF ._1ewLV ._3u99Z {
  min-height: min(50 / var(--ui-kit-design-width) * 100vw, 
    50px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  border-radius: min(8 / var(--ui-kit-design-width) * 100vw, 
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  padding: min(16 / var(--ui-kit-design-width) * 100vw, 
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._2ZelF ._1ewLV ._2VodM {
  margin: min(24 / var(--ui-kit-design-width) * 100vw, 
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) auto 0;
  width: min(40 / var(--ui-kit-design-width) * 100vw, 
    40px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  height: min(40 / var(--ui-kit-design-width) * 100vw, 
    40px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  border-radius: min(20 / var(--ui-kit-design-width) * 100vw, 
    20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  box-shadow: 0px min(1 / var(--ui-kit-design-width) * 100vw, 
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(10 / var(--ui-kit-design-width) * 100vw, 
    10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) rgba(0, 0, 0, 0.05);
  font-size: min(16 / var(--ui-kit-design-width) * 100vw, 
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._2ZelF ._1ewLV._3aD9L._2HW8m ._2VodM {
  margin: 0 auto min(24 / var(--ui-kit-design-width) * 100vw, 
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._2ZelF ._1ewLV._3aD9L._TFN7_ ._2VodM {
  margin: auto min(24 / var(--ui-kit-design-width) * 100vw, 
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) auto 0;
}
._2ZelF ._1ewLV._3aD9L._1Pk7T ._2VodM {
  margin: auto 0 auto min(24 / var(--ui-kit-design-width) * 100vw, 
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._2odNE ._2iyT2 {
  display: flex;
  padding-top: 24px;
}
._2odNE ._2IuEd {
  flex-direction: column-reverse;
}
._2odNE ._2IuEd ._2NT9z {
  margin-top: 8px;
}
._2odNE ._1E_mp {
  flex-direction: row;
}
._2odNE ._1E_mp ._10CHD {
  flex: 1 1;
}
._2odNE ._1E_mp ._2NT9z {
  margin-right: 8px;
}

._172-K ._2iyT2 {
  padding-top: min(24 / var(--ui-kit-design-width) * 100vw, 
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._172-K ._2IuEd ._2NT9z {
  margin-top: min(8 / var(--ui-kit-design-width) * 100vw, 
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._172-K ._1E_mp ._2NT9z {
  margin-right: min(8 / var(--ui-kit-design-width) * 100vw, 
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._OdOum {
  display: flex;
  align-items: center;
}
._OdOum ._Btvh8 {
  touch-action: pan-x;
  position: relative;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
       user-select: none;
  -webkit-tap-highlight-color: transparent;
}
._OdOum ._Btvh8 ._2ScBH {
  width: 40px;
  height: 24px;
  background: var(--textDisabled);
  box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  transition: all 0.2s ease;
  cursor: pointer;
}
._OdOum ._Btvh8 ._U6vpT {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--neutralPrimary);
  box-sizing: border-box;
  cursor: pointer;
}
._OdOum ._Btvh8 ._mUiFQ {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  cursor: pointer;
}
._OdOum ._2x8p2 {
  margin-left: 8px;
  cursor: default;
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
._OdOum ._2GgaG ._2ScBH {
  background: var(--successDefault);
  box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
}
._OdOum ._2GgaG ._U6vpT {
  left: 18px;
}
._OdOum ._2IlJR ._2ScBH {
  cursor: not-allowed;
  background: var(--textDisabled);
  box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
}
._OdOum ._2IlJR._2GgaG ._2ScBH {
  cursor: not-allowed;
  background: transparent;
  box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}
._OdOum ._2IlJR._2GgaG ._2ScBH::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: var(--successDefault);
  opacity: 0.5;
}
._OdOum ._2IlJR ._U6vpT {
  cursor: not-allowed;
}

._2EE83 ._Btvh8 ._2ScBH {
  width: min(40 / var(--ui-kit-design-width) * 100vw, 
    40px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  height: min(24 / var(--ui-kit-design-width) * 100vw, 
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  box-shadow: inset 0px 0px min(4 / var(--ui-kit-design-width) * 100vw, 
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(1 / var(--ui-kit-design-width) * 100vw, 
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) rgba(0, 0, 0, 0.1);
  border-radius: min(32 / var(--ui-kit-design-width) * 100vw, 
    32px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._2EE83 ._Btvh8 ._U6vpT {
  top: min(2 / var(--ui-kit-design-width) * 100vw, 
    2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  left: min(2 / var(--ui-kit-design-width) * 100vw, 
    2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  width: min(20 / var(--ui-kit-design-width) * 100vw, 
    20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  height: min(20 / var(--ui-kit-design-width) * 100vw, 
    20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._2EE83 ._Btvh8 ._mUiFQ {
  height: min(1 / var(--ui-kit-design-width) * 100vw, 
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  margin: max(-1 / var(--ui-kit-design-width) * 100vw, 
    -1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  width: min(1 / var(--ui-kit-design-width) * 100vw, 
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._2EE83 ._2x8p2 {
  margin-left: min(8 / var(--ui-kit-design-width) * 100vw, 
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: min(16 / var(--ui-kit-design-width) * 100vw, 
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  line-height: min(24 / var(--ui-kit-design-width) * 100vw, 
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._2EE83 ._2GgaG ._2ScBH {
  box-shadow: inset 0px 0px min(4 / var(--ui-kit-design-width) * 100vw, 
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(1 / var(--ui-kit-design-width) * 100vw, 
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) rgba(0, 0, 0, 0.1);
}
._2EE83 ._2GgaG ._U6vpT {
  left: min(18 / var(--ui-kit-design-width) * 100vw, 
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._2EE83 ._2IlJR ._2ScBH {
  box-shadow: inset 0px 0px min(4 / var(--ui-kit-design-width) * 100vw, 
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(1 / var(--ui-kit-design-width) * 100vw, 
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) rgba(0, 0, 0, 0.1);
}
._2EE83 ._2IlJR._2GgaG ._2ScBH {
  box-shadow: inset 0px 0px min(4 / var(--ui-kit-design-width) * 100vw, 
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(1 / var(--ui-kit-design-width) * 100vw, 
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) rgba(0, 0, 0, 0.1);
}
._3N02T {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  overflow: hidden;
  background: var(--neutralPrimary);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  opacity: 0;
}
._3N02T ._2fLRW {
  display: flex;
  flex-basis: 42px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: var(--neutralPrimary);
}
._3N02T ._10eLs {
  background-color: var(--successDefault);
}
._3N02T ._3Dt90 {
  background-color: var(--errorDefault);
}
._3N02T ._2zg4J {
  background-color: var(--warningDefault);
}
._3N02T ._1pXiX {
  display: flex;
  align-items: center;
  flex: 1 1;
  margin: 12px 8px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
._3N02T ._1GFn6 {
  color: var(--successDefault);
}
._3N02T ._2lKqr {
  color: var(--errorDefault);
}
._3N02T ._2w96P {
  color: var(--warningDefault);
}
._3N02T ._2WB86 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #858c9b;
  margin-right: 12px;
}
._2Pqjg {
  animation: _M3BFZ 0.4s;
  transform: translateY(0);
  opacity: 1;
}
._La_pC {
  animation: _1gEel 0.4s;
}

._JLgaA {
  box-shadow: 0px min(1 / var(--ui-kit-design-width) * 100vw, 
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(10 / var(--ui-kit-design-width) * 100vw, 
    10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) rgba(0, 0, 0, 0.05);
  border-radius: min(8 / var(--ui-kit-design-width) * 100vw, 
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._JLgaA ._2fLRW {
  flex-basis: min(42 / var(--ui-kit-design-width) * 100vw, 
    42px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  font-size: min(14 / var(--ui-kit-design-width) * 100vw, 
    14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._JLgaA ._1pXiX {
  margin: min(12 / var(--ui-kit-design-width) * 100vw, 
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(8 / var(--ui-kit-design-width) * 100vw, 
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: min(12 / var(--ui-kit-design-width) * 100vw, 
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  line-height: min(18 / var(--ui-kit-design-width) * 100vw, 
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}
._JLgaA ._2WB86 {
  font-size: min(14 / var(--ui-kit-design-width) * 100vw, 
    14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
  margin-right: min(12 / var(--ui-kit-design-width) * 100vw, 
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );
}

@keyframes _M3BFZ {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes _1gEel {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
._2w0dH {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

._3qxPM {
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
/* NEUTRAL COLORS */
/* TEXT / STATES */
/* PRIMARY COLORS */
/* ERROR COLORS */
/* WARNING COLORS */
/* SUCCESS COLORS */
/* INFO COLORS */
/* CAUTION COLORS */
/* Modal */
/* OTHERS COLORS */

:root {
  --neutralPrimary: #ffffff;
  --neutralBase: #FAFAFB;
  --neutralSecondary: #F5F5F6;
  --neutralBorder: #ECEDEF;
  --neutralBorderBlack: #202020;
  --neutralSecondaryBlack: #1C1C1C;
  --neutralBaseBlack: #131313;
  --neutralPrimaryBlack: #000000;
  --textPrimary: #0F192E;
  --textSecondary: rgba(15, 25, 46, 0.6);
  --textTertiary: rgba(15, 25, 46, 0.4);
  --textDisabled: rgba(15, 25, 46, 0.2);
  --textPrimaryWhite: #ffffff;
  --textSecondaryWhite: rgba(255, 255, 255, 0.8);
  --textTertiaryWhite: rgba(255, 255, 255, 0.4);
  --textDisabledWhite: rgba(255, 255, 255, 0.2);
  --primaryBackground: #FFF4F6;
  --primaryBorder: #F06F86;
  --primaryDefault: #BC1433;
  --primaryHover: #900B24;
  --primaryPressed: #600516;
  --errorBackground: #FFF4F6;
  --errorBorder: #FF9BAF;
  --errorDefault: #F33055;
  --errorHover: #D70029;
  --errorPressed: #B40022;
  --warningBackground: #FFFAE0;
  --warningBorder: #FFE17F;
  --warningDefault: #FAB300;
  --warningHover: #DD9E00;
  --warningPressed: #B98400;
  --successBackground: #F2FEF9;
  --successBorder: #68DFA7;
  --successDefault: #12A25F;
  --successHover: #0A7D47;
  --successPressed: #05532E;
  --infoBackground: #F1FAFE;
  --infoBorder: #75CCEA;
  --infoDefault: #008BBA;
  --infoHover: #00759D;
  --infoPressed: #005A79;
  --cautionBackground: #FFF5E8;
  --cautionBorder: #FFB273;
  --cautionDefault: #F56200;
  --cautionHover: #DD5900;
  --cautionPressed: #A94400;
  --modalOverlayBlack: rgba(0, 0, 0, 0.8);
  --modalOverlayWhite: rgba(255, 255, 255, 0.8);
  --reddish: linear-gradient(135deg, #de2b4c 0%, #bc1433 64.05%);
  --clear: #F5FAFF;
  --skin: #F0D9C4;
  --nose: #908376;
}
